import React, { useState } from 'react';
// import { NavLink, Redirect } from 'react-router-dom';
// import '../../assets/scss/style.scss';
import Aux from '../hoc/_Aux';
// import Breadcrumb from '../layout/AdminLayout/Breadcrumb';
import { site_ip } from '../globalSetting';
import axios from 'axios';
import './Learner.css';
import { Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Trip from '../../image/trip_up.png';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // onSubmitClick = async (e) => {
  //   // localStorage.setItem('page_refresh', 0);
  //   e.preventDefault();
  //   await fetch(site_ip + '/auth/adminSignin', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       admin_email: this.state.email,
  //       password: this.state.password,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(data.content.role_id);
  //       if (data.Success === 200) {
  //         localStorage.setItem('user_id', data.content.admin_id);
  //         localStorage.setItem('role_id', data.content.role_id);
  //         localStorage.setItem('user_name', data.content.name);
  //         this.setState({ user_name: data.content.name });

  //         if (data.content.role_id === 3) {
  //           setTimeout(() => {
  //             this.redirectNow();
  //           }, 1000);
  //         } else {
  //           alert('You do not have permission to access Admin dashboard');
  //         }
  //       } else {
  //         alert('Email or Password is incorrect!');
  //       }
  //     });
  // };

  const handleLogin = () => {
    axios({
      method: 'post',
      url: site_ip + '/auth/adminSignin',
      data: {
        admin_email: email,
        password: password,
      },
      dataType: 'json',
    })
      .then(function (response) {
        console.log(response?.data.Success);
        if (response?.data.Success === 200) {
          localStorage.setItem('user_id', response?.data.content.admin_id);
          localStorage.setItem('role_id', response?.data.content.role_id);
          localStorage.setItem('user_name', response?.data.content.name);
          // this.setState({ user_name: response?.data.content.name });

          if (response?.data.content.role_id === 3) {
            redirectUser()
          } else {
            alert('You do not have permission to access Admin dashboard');
          }
        } else {
          alert('Email or Password is incorrect!');
        }
      })
      .catch(function (error) {
        alert('Invalid credentials');
        console.log('error1' + error);
      });
  };

  const redirectUser = () => {
    setTimeout(() => {
      window.location.href = '/admin/dashboard';
    }, 1000);
  };

  return (
    
      <div>
        <Row style={{ margin: '0px', minHeight:"100vh" }}>
          <Col></Col>  
          <Col className="align-items-center justify-content-center" style={{flexDirection:"column", display:"flex"}}>
            {/* <img src={Trip} className="header-logo-img" />   */}
            <p className="text-center heading-1" style={{fontWeight:"bold"}}>
                ADMIN LOGIN
            </p>
            <div className="login-box-div">
              {/* <Form> */}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <p className='registerLabel'>Email</p>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="user_email"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className='registerLabel'>Password</p>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </Form.Group>
                <div className="align-items-center justify-content-center flex">
                  <button className='primary-btn' onClick={()=>handleLogin()}>LOGIN</button>
                </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
      </div>
  );
}

export default Login;
