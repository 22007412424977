import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { site_ip } from '../constants';
import { useNavigate } from 'react-router-dom';
import "../App.css";
import { publicIpv4 } from 'public-ip';
const { detect } = require('detect-browser');
const browser = detect();
const HomePage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [accessModalVisible, setAccessModalVisible] = useState(false);
    const [registerModal, setRegisterModal] = useState(null);
    useEffect(() => {
        getDoctorSpecialisation();
        getStateList();
    }, [])

    const [specialization, setSpecialization] = useState([])
    const getDoctorSpecialisation = () => {
        axios
            .get(site_ip + '/getDoctorSpecialisation')
            .then(function (response) {
                setSpecialization(response.data.content)
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    };

    const [stateList, setStateList] = useState([])
    const getStateList = () => {
        axios
            .get(site_ip + '/getStatesByCountry/1')
            .then(function (response) {
                setStateList(response.data.Content)
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    };

    const validPromoCode = (val) => {
        setRegisterModal(val)
    }

    const validateEmail = (mail) => {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                mail
            )
        ) {
            return true;
        }
        return false;
    };

    const continueToContent = () => {
        if (!validateEmail(email)) {
            alert("Please enter a valid email address")
        } else {
            axios
                .get(site_ip + '/auth/validateEmail/' + email)
                .then(function (response) {
                    if(response.data.Success === 401){
                        alert("Please enter a valid email address")
                    }else{
                        userLoggedIn(response.data)
                    }
                    
                })
                .catch(function (error) {
                    alert("Please enter a valid email address")
                });
        }
    }

    const userLoggedIn = async (data) => {
        let ip = await publicIpv4()
        let obj = {
            user_id: data?.Content[0]?.user_id,
            user_ip: ip,
            user_browser: browser.name,
            user_device: browser.os,
            login_datetime: new Date()
        }

        axios
            .post(site_ip + '/setUserLogin', obj)
            .then(function (response) {
                navigate("/product", { state: { email: email, type:"login" } })
            })
            .catch(function (error) {
                alert("Please enter a valid email address")
            });
    
}

return (
    <div>
        <div className="homepage">
            <img src="./images/McMaster_Login_Page.jpg" className="background-image-bg" />
            <img src="./images/McMaster_Login_Page_Mobile.jpg" className="background-image-small" />
            <div className="home-actionbar">
                <Row style={{ margin: 0 }}>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: 'flex-end', alignItems: "center" }}>
                                <p className='login-form-label' style={{ marginBottom: "15px" }}>First time user Click here to</p>
                                <button className="login-submit-button" onClick={() => setAccessModalVisible(true)}>
                                    Redeem access code
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box">
                                <p className='login-form-label' style={{ marginBottom: "15px" }}>Registered User</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p className='login-form-label'>Email ID:</p>
                                    <input className="login-form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <p className="login-input-instruction">(Please enter email id used during registration process)</p>
                                <button className="login-submit-button" onClick={() => continueToContent()}>
                                    Submit to Access Content
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="home-actionbar-mobile">
                <Row style={{ margin: 0 }}>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{height:"100px", position: "relative", display: "flex", flexDirection: "column", justifyContent: 'flex-end', alignItems: "center" }}>
                                <p className='login-form-label' style={{ marginBottom: "15px" }}>First time user Click here to</p>
                                <button className="login-submit-button" onClick={() => setAccessModalVisible(true)}>
                                    Redeem access code
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col style={{marginTop:"30px"}}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box">
                                <p className='login-form-label' style={{ marginBottom: "15px" }}>Registered User</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p className='login-form-label'>Email ID:</p>
                                    <input className="login-form-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <p className="login-input-instruction">(Please enter email id used during registration process)</p>
                                <button className="login-submit-button" onClick={() => continueToContent()}>
                                    Submit to Access Content
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className='note-div'>
            <p className="note-para">Your personal data shall be processed by medical publisher Medycyna Praktyczna S.J. with its offices at 2 Rejtana st., 30-510 Kraków, Poland, Tax ID [NIP] 6790044689 (Personal Data Controller – the original publisher of the McMaster Textbook of Internal Medicine South Asian Edition), to give you free access to all resources of its e-versions.</p>
        </div>
        {accessModalVisible ?
            <RedeemAccessCode validPromoCode={(val) => validPromoCode(val)} onClose={() => setAccessModalVisible(false)} /> : null}

        {registerModal !== null ?
            <RegisterModal continueToNext={(val) => navigate("/product", { state: { email: val, type:"registration" } })} code={registerModal} stateList={stateList} specialization={specialization} onClose={() => setRegisterModal(false)} /> : null}

    </div>
)
}

export default HomePage

const RegisterModal = (props) => {
    const blankValues = {
        first_name: "",
        last_name: "",
        user_email: "",
        user_mobile: "",
        city_id: "",
        state_id: "",
        doc_spec_id: "",
        coupon_code: props.code,
        password: "",
        other_city: ""
    }

    const [values, setValues] = useState(blankValues);
    const [showOtherCityInput, setShowOtherCityInput] = useState(false);
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "state_id") {
            setValues({ ...values, [name]: value, "city_id": "" })
            getCityList(value)
        }else if (name === "city_id") {
            setValues({ ...values, [name]: value }) 
           // console.log(value);
          if(value=='0'){
            setShowOtherCityInput(true);
          }else{
            setShowOtherCityInput(false);
          }
        } else {
            setValues({ ...values, [name]: value })
        }
    }

    const [cityList, setCityList] = useState([])
    const getCityList = (id) => {
        axios
            .get(site_ip + '/getCities/' + id)
            .then(function (response) {
                setCityList(response.data.Content)
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    };

    const validateEmail = (mail) => {
        if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                mail
            )
        ) {
            return true;
        }
        return false;
    };

    const phonenumber = (mobile) => {
        var phoneno = /^\d{10}$/;
        if (String(mobile).match(phoneno)) {
            return true;
        } else {
            return false;
        }
    };
const pwcheck=(pw)=>{
 return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(pw) // consists of only these    
 }
    const registerUser = () => {
        let req = { ...values };
          //console.log(req);
         // return;
        if (req.first_name.trim() === "") {
            alert("First Name cannot be blank.")
            return
        } else if (!validateEmail(req.user_email)) {
            alert("Please add a valid email.")
            return;
        } else if (!phonenumber(req.user_mobile)) {
            alert("Please add a valid contact number.")
            return;
        } else if (req.state_id === "") {
            alert("Please select state.")
            return
        } else if (req.city_id === "") {
            alert("Please select city.")
            return
        }else if (req.city_id == 0 && req.other_city==="") {
            alert("Please enter your city name.")
            return
        } else if (req.doc_spec_id === "") {
            alert("Please select specialization.")
            return
        } else if (req.password.trim().length < 8) {
            alert("Password must be at least 8 characters and include 1 number and 1 uppercase letter")
            return
        }else if (!pwcheck(req.password.trim())) {
            alert("Password must be at least 8 characters and include 1 number and 1 uppercase letter")
            return
        }
        axios
            .post(site_ip + '/auth/signup', req)
            .then(function (response) {
                props.continueToNext(req.user_email)
            })
            .catch(function (error) {
                console.log('error' + error);
            });
    };

    return (
        <Modal size="lg" show={true} backdrop="static">
            <Modal.Body>
                <div>
                    <p className='login-form-label' style={{ marginBottom: "15px", fontSize: "16px" }}>Register</p>
                    <div style={{ borderBottom: "1px solid #ccc", marginBottom: "25px" }}></div>
					 <div>
					 <p><strong>Before you begin, take a moment to go through these essential details<br/>
1. Kindly enter your correct email ID, as an email activation link will be sent to this address<br/>
2. Please enter the same email id and password on the following page for completing the registration process.<br/></strong>
</p>
					 </div>
                    <div>
                        <Row>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >First Name <span style={{ color: "#ff0000" }}>*</span></p>
                                <input type={"text"} className="modal-input" name="first_name" value={values.first_name} onChange={handleChange} />
                            </Col>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Last Name</p>
                                <input type={"text"} className="modal-input" name="last_name" value={values.last_name} onChange={handleChange} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "15px" }}>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Email <span style={{ color: "#ff0000" }}>*</span></p>
                                <input type={"text"} className="modal-input" name="user_email" value={values.user_email} onChange={handleChange} />
                            </Col>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Mobile <span style={{ color: "#ff0000" }}>*</span></p>
                                <input type={"text"} className="modal-input" name="user_mobile" value={values.user_mobile} onChange={handleChange} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "15px" }}>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >State <span style={{ color: "#ff0000" }}>*</span></p>
                                <select className="modal-input" name="state_id" value={values.state_id} onChange={handleChange}>
                                    <option value={""}>--Select--</option>
                                    {props.stateList.map((item, index) => {
                                        return (
                                            <option key={item.state_id} value={item.state_id}>{item.state_name}</option>
                                        )
                                    })}
                                </select>
                            </Col>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >City <span style={{ color: "#ff0000" }}>*</span><small style={{ color: "#c00", fontWeight: "400" }}>If you do not find your city in drop down, please select other and enter your city manually</small></p>
                                <select className="modal-input" name="city_id" value={values.city_id} onChange={handleChange}>
                                    <option value={""}>--Select--</option>
                                    {cityList.map((item, index) => {
                                        return (
                                            <option key={item.city_id} value={item.city_id}>{item.city_name}</option>
                                            
                                        )
                                    })}
                                    <option value="0">Other</option>
                                </select>
                            </Col>
                        </Row>
                        {showOtherCityInput && (
                        <Row style={{ marginTop: "15px"}} >

                        <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >City Name <span style={{ color: "#ff0000" }}>*</span></p>
                                <input type={"text"} className="modal-input" name="other_city" value={values.other_city} onChange={handleChange}  />
                            </Col>
                           
                        </Row> )}
                        <Row style={{ marginTop: "15px" }}>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Specialization <span style={{ color: "#ff0000" }}>*</span></p>
                                <select className="modal-input" name="doc_spec_id" value={values.doc_spec_id} onChange={handleChange}>
                                    <option value={""}>--Select--</option>
                                    {props.specialization.map((item, index) => {
                                        return (
                                            <option key={item.doc_spec_id} value={item.doc_spec_id}>{item.specilisation}</option>
                                        )
                                    })}
                                </select>
                            </Col>
                            <Col>
                                <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Password <span style={{ color: "#ff0000" }}>*</span></p>
                                <input type={"text"} className="modal-input" name="password" value={values.password} onChange={handleChange} />
								<span style={{ color: "#ff0000" }}>Password must be at least 8 characters and include 1 number and 1 uppercase letter</span>

                            </Col>
                        </Row>
                    </div>
                    <div >
                        <Row>
                            <Col></Col>
                            <Col style={{ display: "flex", marginTop: "40px" }}>
                                <button className="secondary-button" onClick={() => props.onClose()} >
                                    Close
                                </button>
                                <div style={{ width: "15px" }}></div>
                                <button className="login-submit-button" onClick={() => registerUser()} >
                                    Submit
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const RedeemAccessCode = (props) => {
    const [accessCode, setAccessCode] = useState("")
    const [errorMsg, setErrorMsg] = useState(null);

    const validatePromoCode = () => {
        if (accessCode.trim() === "") {
            setErrorMsg("Access Code cannot be blank.")
            return
        }
        axios
            .get(site_ip + '/getPromocodeById/' + accessCode.trim())
            .then(function (response) {
                props.validPromoCode(accessCode.trim())
            })
            .catch(function (error) {
                setErrorMsg("Token Code does not exist! Please provide valid token.")
                console.log('error' + error);
            });
    };

    useEffect(() => {
        setErrorMsg(null)
    }, [accessCode])

    return (
        <Modal size="sm" show={true} backdrop="static">
            <Modal.Body>
                <div>
                    <p className='login-form-label' style={{ marginBottom: "15px", fontSize: "16px" }}>Redeem Access</p>
                    <div style={{ borderBottom: "1px solid #ccc", marginBottom: "25px" }}></div>
                    <div>
                        <p className='login-form-label' style={{ color: "#000000", fontWeight: "400" }} >Access Token</p>
                        <input type="text" className="modal-input" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                        {errorMsg !== null ? <p style={{ fontSize: "11px", color: "#ff0000" }}>{errorMsg}</p> : null}
                    </div>
                    <div style={{ display: "flex", marginTop: "40px" }}>
                        <button className="secondary-button" onClick={() => props.onClose()} >
                            Close
                        </button>
                        <div style={{ width: "15px" }}></div>
                        <button className="login-submit-button" onClick={() => validatePromoCode()} >
                            Verify
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
