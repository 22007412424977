import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
// import { site_ip } from '../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import "../App.css";

const Step2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(()=>{
        if(location.state !== null){
        }else{
            navigate('/');
        }
    }, [])

    const redirectUserToNextSite = () =>{
        window.open("https://www.mp.pl/konto/rejestracja/krok-1?lng=en&email="+location.state.email+"&rfr=https://empendium.com/mcmtextbook-sae/");
    }
    const redirectRegUserToNextSite = () =>{
        window.open("https://secure.mp.pl/konto/login/en?email="+location.state.email+"&rfr=https://empendium.com/mcmtextbook-sae/");
    }

    return (
        <div className="homepage">
            <img src="./images/McMaster_Brading_page.jpg" className="background-image-bg" />
            <img src="./images/McMaster_Branding_page_mobile.jpg" className="background-image-small" />
            <div className="home-actionbar">
                <Row style={{ margin: 0 }}>
                    {location.state.type === "registration" ? 
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{height:"50px", display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={() => redirectUserToNextSite()}>
                                <button className='linkaction-button' onClick={() => redirectUserToNextSite()} >Continue Registration</button>
                            </div>
                        </div>
                    </Col> :
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{height:"50px", display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={() => redirectRegUserToNextSite()}>
                                <button className='linkaction-button' onClick={() => redirectRegUserToNextSite()} >Enter Content.</button>
                            </div>
                        </div>
                    </Col>}
                </Row>
            </div>
            <div className="home-actionbar-mobile" style={{position:"absolute", bottom:"30px", width:"100%"}}>
                <Row style={{ margin: 0 }}>
                    {location.state.type === "registration" ? 
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{height:"50px", display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={() => redirectUserToNextSite()}>
                                <button className='linkaction-button' onClick={() => redirectUserToNextSite()} >Continue Registration</button>
                            </div>
                        </div>
                    </Col> :
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="home-link-box" style={{height:"50px", display:"flex", alignItems:"center", justifyContent:"center", cursor:"pointer"}} onClick={() => redirectRegUserToNextSite()}>
                                <button className='linkaction-button' onClick={() => redirectRegUserToNextSite()} >Enter Content.</button>
                            </div>
                        </div>
                    </Col>}
                </Row>
            </div>
        </div>
    )
}

export default Step2