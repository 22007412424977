import React, { useState, useEffect } from 'react';
import { site_ip } from '../globalSetting';
import { Row, Col, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './common.css';
import axios from 'axios';
function Dashboard() {
  const [usageNos, setUsageNos] = useState(0);
  const [couponNos, setCouponNos] = useState(0);
  const [redeemedNos, setRedeemedNos] = useState(0);
  useEffect(() => {
    getDashboard();
  }, [])

  const getDashboard = () => {
    axios
      .get(site_ip + '/adminDashboardStats')
      .then((res) => {
        if (res?.data?.Coupon_redeemed !== undefined && res?.data?.Coupon_redeemed?.length > 0) {
          setRedeemedNos(res?.data?.Coupon_redeemed[0]?.coupon_used)
        }
        if (res?.data?.Coupons !== undefined && res?.data?.Coupons?.length > 0) {
          setCouponNos(res?.data?.Coupons[0]?.coupon)
        }
        if (res?.data?.Usage !== undefined && res?.data?.Usage?.length > 0) {
          setUsageNos(res?.data?.Usage[0]?.usage_count)
        }
      }).catch((err) => console.log(err));
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="dashboard-card-box">
            <p className="dashboard-card-number">{couponNos}</p>
            <p className="dashboard-card-label">Total Coupon Issued</p>
          </div>
        </Col>
        <Col>
          <div className="dashboard-card-box">
            <p className="dashboard-card-number">{redeemedNos}</p>
            <p className="dashboard-card-label">Total Coupon Redeemed</p>
          </div>
        </Col>
        <Col>
          <div className="dashboard-card-box">
            <p className="dashboard-card-number">{usageNos}</p>
            <p className="dashboard-card-label">Total Usage</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Dashboard;
