import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopBar from './content/common/TopBar';
import AuthHeader from './content/common/AuthHeader';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './afterlogin.css';
import './content/homepage.css';
import LeftSideBar from './LeftSideBar';
import Login from './content/login';
import Signup from './content/Signup';
import Dashboard from './content/Dashboard';

import CouponUsageReport from './content/Reports/CouponUsageReport';
import MonthlyLoginCount from './content/Reports/MonthlyLoginCount';
import DayWiseLogin from './content/Reports/DayWiseLogin';
import UsageAllDoctors from './content/Reports/UsageAllDoctors';

import Top10Articles from './content/Reports/Top10Articles';
import UnderDevelopment from './content/UnderDevelopment';

// import Profile from "../pages/Profile";
// import UpdatePassword from "../pages/ChangePassword";

// Logout
// import Logout from './content/recruitment/Logout/Logout';

function App() {

  const LayoutOfLoginAndSignup = (children) => (
    <>
      {/* <AuthHeader />  */}
      {children}
    </>
  );

  const LayoutOfAfterLogin = (children) => (
    <>
      <TopBar />
      <div className="theme-background">
        <LeftSideBar />
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  const LayoutOfAfterLoginOnlyHeader = (children) => (
    <>
      <TopBar />
      <div className="theme-background">
        <div style={{ padding: "15px", width: "100%" }}>
          <div className="content-wrapper" style={{ overflowY: "auto", height: "calc(100vh - 80px)", overflowX: "hidden" }}> {children}</div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={LayoutOfLoginAndSignup(<Login />)}
        />{' '}
        <Route
          exact
          path="/signup"
          layout={LayoutOfLoginAndSignup}
          element={<Signup />}
        />{' '}
        <Route
          exact
          path="/dashboard"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Dashboard />)}
        />
        <Route
          exact
          path="/reports/monthly-login-count"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<MonthlyLoginCount />)}
        />
		<Route
          exact
          path="/reports/day-wise-logins"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<DayWiseLogin />)}
        />
		<Route
          exact
          path="/reports/usage-all-doctors"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UsageAllDoctors />)}
        />
		
		<Route
          exact
          path="/reports/coupon_usage_report"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<CouponUsageReport />)}
        />
        <Route
          exact
          path="/reports/top_accessed_articles"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<Top10Articles />)}
        />
        <Route
          exact
          path="/reports/under_development"
          layout={LayoutOfAfterLogin}
          element={LayoutOfAfterLogin(<UnderDevelopment />)}
        />
        {/* <Route
          exact
          path="/profile"
          element={LayoutOfAfterLogin(<Profile />)}
        />{' '}
        <Route
          exact
          path="/update-password"
          element={LayoutOfAfterLogin(<UpdatePassword />)}
        />{' '}
        <Route
          exact
          path="/logout"
          element={LayoutOfAfterLogin(<Logout />)}
        />{' '} */}
      </Routes>
    </>
  );
}

export default App;
