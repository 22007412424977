import { Button, Card, Col, Row, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import Chart from "../Charts/DayWiseVisit";
import ChartScrollable from "../Charts/DayWiseVisitScrollable";
import Aux from "../../hoc/_Aux";
import { site_ip } from '../../globalSetting';
import "./newdesigncss.css";
import download from "../../assets/images/icons/11.png";

export default function VisitsByDay() {
  const [month, setMonth] = useState(new Date());
  var date = new Date(month),
    y = date.getFullYear(),
    m = date.getMonth();

  let emptySearch = {
    date_from: new Date(y, m, 2).toISOString().split("T")[0],
    // date_to: new Date(y, m + 1, 0).toISOString().split('T')[0],
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [reportdata, setReportData] = useState([]);
  const [item, setItem] = useState(emptySearch);

  const [selectedMonth, setSelectedMonth] = useState("")
  const [monthList, setMonthList] = useState([])
  const getReportMonths = async (event) => {
    await fetch(site_ip + '/getReportsMonthYear', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMonthList(response?.Data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReportMonths();
  }, [])


  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line
  }, [month]);

  useEffect(()=>{
    if(selectedMonth === ""){
      handleSubmit();
    }else{
      let split_date = selectedMonth.split("-");
      let y = split_date[1];
      let monthIndex = monthNames.findIndex(month => month === split_date[0]);
      let m = monthIndex + 1;
      if (m < 10) m = '0' + m;
      let data = {
        date_from: y + '-' + m + '-01',
        date_to: new Date(y, m, 1).toISOString().split('T')[0],
      };
      handleSubmit(data);
    }
  }, [selectedMonth])

  const csvdata = reportdata.map((elt, index) => [
    index + 1,
    elt.dayvisits,
    elt.visits,
  ]);
  csvdata.unshift(["Sr No.", "Date", "Total Visits"]);

  const handleSubmit = async (item) => {
    let data = emptySearch;
    if (item !== undefined) {
      data = item;
    }
    await fetch(site_ip + "/visitsByDay", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((re) => {
        // console.log(re);
        setReportData(re.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Get List of Months
  let months = [];
  // let currentYear = new Date().getFullYear();
  // let currentMonth = new Date().getMonth();
  // for (let i = currentYear; i >= 2023; i--) {
  //   while (parseInt(currentMonth + 1) > 0) {
  //     if (currentYear === 2023 && parseInt(currentMonth + 1) === 5) break;
  //     if (currentMonth + 1 > 6)
  //       months.push({
  //         month_year: currentYear + "-" + parseInt(currentMonth + 1),
  //       });
  //     else
  //       months.push({
  //         month_year: currentYear + "-0" + parseInt(currentMonth + 1),
  //       });
  //     currentMonth--;
  //   }
  //   currentYear--;
  //   currentMonth = 11;
  // }
  return (
    <Aux>
      <div className="contentOutLine">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="pageTitleDiv">
            <p>Day Wise Logins</p>
          </div>
        </div>
        <Row>
          <Col md={5} style={{ display: "flex" }}>
            <div className="filterFormField mt-5 ">
              <p className="formLabel" style={{ textAlign: "center" }}>
                Data showing for :
              </p>
              <p className="formLabel">
                {selectedMonth !== "" ? selectedMonth : (monthNames[m] + " " + y)}
              </p>
            </div>
          </Col>
          <Col md={2} style={{ display: "flex" }}>
            <div className="filterFormField  mt-5 ">
              <p className="formLabel">
                <Form.Label>Change Month</Form.Label>
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="filterFormField  mt-5">
              <Form.Control
                as="select"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                style={{marginTop:"10px"}}
              >
                <option value="">Select Month</option>
                {monthList?.map((item, index) => {
                  return (
                    <option value={item?.logindate} key={item?.logindate}>{item?.logindate}</option>
                  )
                })}
              </Form.Control>
            </div>  
          </Col>
          <Col className="mt-5 ">
            <CSVLink
              filename={"DayWiseLoginsCount.csv"}
              color="primary"
              style={{ float: "right", marginRight: "10px" }}
              className="downloadButton"
              data={csvdata}
            >
              <img
                src={download}
                alt="download report"
                height="60px"
                width="100%"
              />
            </CSVLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {reportdata.length > 32 ? (
                  <ChartScrollable data={reportdata} />
                ) : (
                  <Chart data={reportdata} />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Aux>
  );
}
