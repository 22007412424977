import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Aux from '../../hoc/_Aux';
import axios from 'axios';
//import '../../../../../src/components/MIS/mis.css';
import { CSVLink } from 'react-csv';
import Chart from '../Charts/LoginCountByMonthYear';
import download from '../../assets/images/icons/11.png';

import { site_ip } from '../../globalSetting';
import './newdesigncss.css';

export default function TotalLoginCountByMonthYear() {
  const [reportdata, setReportData] = useState([]);
  let count = 0;
  const [total, setTotal] = useState(0);
  const getReports = async () => {
    await axios
      .get(site_ip + '/TotalLoginCountByMonthYear')
      .then((response) => {
        console.log(response);
        setReportData(response.data.Data);
        for (let i = 0; i < response.data.Data.length; i++) {
          count = count + parseInt(response.data.Data[i].logins);
        }
        setTotal(count);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    getReports();
    // eslint-disable-next-line
  }, []);
  const csvdata = reportdata.map((elt, index) => [
    index + 1,
    elt.monthyear,
    elt.logins,
  ]);
  csvdata.unshift(['Sr No.', 'Month-Year', 'Total Logins']);
  return (
    <Aux>
      <div className="contentOutLine">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="pageTitleDiv">
            <p>Monthly Login Count</p>
          </div>
        </div>
        <Row>
          <Col md={5}></Col>
          <Col md={5} className="d-flex align-items-center ">
            <div className="bg-primary rounded p-2">
              <p style={{ color: '#fff' }}>
                Total Login Count Till Date : {total}
              </p>
            </div>
          </Col>
          <Col md={2}>
            <CSVLink
              filename={'MonthlyLoginCount.csv'}
              color="primary"
              style={{ float: 'right', marginRight: '10px' }}
              className="downloadButton"
              data={csvdata}
            >
              <img src={download} alt="download report" height="60px" />
            </CSVLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Chart data={reportdata} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Aux>
  );
}
