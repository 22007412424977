import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Admin from "./admin";
import Home from './screen/Home';
import Product from './screen/Step2';
// import Dashboard from './screen/Dashboard';
function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/admin/*"
          element={<Admin />}
        />{' '}
        <Route
          exact
          path="/"
          element={<Home />}
        />
        <Route
          exact
          path="/product"
          element={<Product />}
        />
      </Routes>
    </Router>
  );
}

export default App;
