import React, { PureComponent } from "react";
// import {Col, Row} from 'react-bootstrap';

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

export default class Example extends PureComponent {
  render() {
    const data = this.props.data;

    // Let's make data
    let dates = [];
    let visits = [];

    for (let i = 0; i < data.length; i++) {
      dates.push(data[i].dayvisits);
      visits.push(parseInt(data[i].visits));
    }

    const options = {
      chart: {
        type: "area",
      },
      title: {
        text: this.props.title,
      },
      xAxis: {
        categories: dates,
        title: {
          text: null,
        },
        min: 0,
        max: 30,
        scrollbar: {
          enabled: true,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "In Nos",
          align: "center",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        valueSuffix: " No(s)",
      },
      plotOptions: {
        series: {
          pointPadding: 0.3,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.f}",
          },
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true,
      },
      credits: {
        enabled: false,
      },

      series: [
        {
          name: "Total Visits",
          data: visits,
          color: "#5B9BD5",
        },
      ],
    };
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}
