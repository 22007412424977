import { Card, Col, Row, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { CSVLink } from 'react-csv';
import download from '../../assets/images/icons/11.png';

import Aux from '../../hoc/_Aux';
import { site_ip } from '../../globalSetting';
import './newdesigncss.css';
import _ from 'lodash';
const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgba(189, 188, 188, 0.55)',
    },
  },
});
export default function UsageAllDoctors() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [month, setMonth] = useState(new Date());
  var date = new Date(month),
    y = date.getFullYear(),
    m = date.getMonth() + 1,
    m1 = date.getMonth();
  if (m < 10) m = '0' + m;

  let emptySearch = {
    date_from: y + '-' + m + '-01',
    date_to: new Date(y, m, 1).toISOString().split('T')[0],
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line
  }, [month]);

  const [reportdata, setReportData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [item, setItem] = useState(emptySearch);
  const classes = useStyles();
  const [sortModel, setSortModel] = useState([
    {
      field: 'visits',
      sort: 'desc',
    },
  ]);

  ///Total Visits Formula
  const total_login = [];
  for (let i = 0; i < reportdata.length; i++) {
    let count = Number(reportdata[i].visits);
    if (count === 0) count = 0;
    else if (count < 10) count = count * 3;
    else if (count >= 10 && count < 25) count = count * 2;
    else if (count >= 25) count = count * 1.5;
    total_login.push(count);
  }
  const contentData = reportdata?.map((v, i) => ({
    ...v,
    sno: i + 1,
    id: i + 1,
    total_login: total_login[i],
  }));
  
  const contentColumns = [
    {
      field: 'doctor',
      headerClassName: 'super-app-theme--header',
      headerName: 'Doctor',
      flex: 1,
      width: 300,
    },
    {
      field: 'user_email',
      headerClassName: 'super-app-theme--header',
      headerName: 'Doctor Email',
      flex: 1,
      width: 200,
    },
    {
      field: 'logincount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Total Login',
      width: 200,
      type: 'number',
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
  };

  const [selectedMonth, setSelectedMonth] = useState("")
  const [monthList, setMonthList] = useState([])
  const getReportMonths = async (event) => {
    await fetch(site_ip + '/getReportsMonthYear', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMonthList(response?.Data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReportMonths();
  }, [])

  useEffect(()=>{
    if(selectedMonth === ""){
      handleSubmit();
    }else{
      let split_date = selectedMonth.split("-");
      let y = split_date[1];
      let monthIndex = monthNames.findIndex(month => month === split_date[0]);
      let m = monthIndex + 1;
      if (m < 10) m = '0' + m;
      let data = {
        date_from: y + '-' + m + '-01',
        date_to: new Date(y, m, 1).toISOString().split('T')[0],
      };
      handleSubmit(data);
    }
  }, [selectedMonth])

  const handleSubmit = async (item) => {
    let data = emptySearch;
    if (item !== undefined) {
      data = item;
    }
    await fetch(site_ip + '/LoginCount', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setReportData(response.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Get List of Months
  let months = [];
  // let currentYear = new Date().getFullYear();
  // let currentMonth = new Date().getMonth();
  // for (let i = currentYear; i >= 2023; i--) {
  //   while (parseInt(currentMonth + 1) > 0) {
  //     if (currentYear === 2023 && parseInt(currentMonth + 1) === 5) break;
  //     if (currentMonth + 1 > 6)
  //       months.push({
  //         month_year: currentYear + '-' + parseInt(currentMonth + 1),
  //       });
  //     else
  //       months.push({
  //         month_year: currentYear + '-0' + parseInt(currentMonth + 1),
  //       });
  //     currentMonth--;
  //   }
  //   currentYear--;
  //   currentMonth = 11;
  // }
  return (
    <Aux>
      <div className="contentOutLine">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="pageTitleDiv">
            <p>Usage of All Doctors - Monthly</p>
          </div>
        </div>
        <Row>
          <Col md={4} style={{ display: 'flex' }}>
            <div className="filterFormField mt-5 ">
              <p className="formLabel" style={{ textAlign: 'center' }}>
                Data showing for :
              </p>
              <p className="formLabel">
                {selectedMonth !== "" ? selectedMonth : (monthNames[m1] + " " + y)}
              </p>
            </div>
          </Col>
          <Col md={2} style={{ display: 'flex' }}>
            <div className="filterFormField  mt-5 ">
              <p className="formLabel">
                <Form.Label>Change Month</Form.Label>
              </p>
            </div>
          </Col>
          <Col md={3}>
            <div className="filterFormField  mt-5 ">
              <Form.Control
                as="select"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                style={{marginTop:"10px"}}
              >
                <option value="">Select Month</option>
                {monthList?.map((item, index) => {
                  return (
                    <option value={item?.logindate} key={item?.logindate}>{item?.logindate}</option>
                  )
                })}
              </Form.Control>
            </div>  
          </Col>
          <Col md={3}>
            <div className="filterFormField  mt-5">
              <CSVLink
                filename={'Usage_All_Doctors.csv'}
                color="primary"
                style={{ float: 'right', marginLeft: '50px' }}
                className="downloadButton"
                data={reportdata}
              >
                <img
                  src={download}
                  alt="download report"
                  height="60px"
                  width="100%"
                />
              </CSVLink>
            </div>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <div
              style={{ height: 600, width: '100%' }}
              className={classes.root}
            >
              <DataGrid
                rows={contentData}
                columns={contentColumns}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                onPageSizeChange={handleChangeRowsPerPage}
                // sortModel={sortModel}
                // onSortModelChange={(model) => {
                //   if (!_.isEqual(model, sortModel)) {
                //     setSortModel(model);
                //   }
                // }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>{' '}
    </Aux>
  );
}
