import React from 'react';
import { useNavigate } from 'react-router-dom';
const BackButton = (props) =>{
    const navigate = useNavigate();
    return(
        <div className="common-back-button">
            <a href="/" onClick={(e) => {e.preventDefault(); navigate(-1)}}><i className="fas fa-arrow-left"></i></a>
            <p>{props.title}</p>
        </div>
    )
}

export default BackButton;