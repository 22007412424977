import React, { PureComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);
export default class Example extends PureComponent {
  render() {
    const data = this.props.data;

    // Let's make data
    let monthyear = [];
    let logincount = [];

    for (let i = 0; i < data.length; i++) {
      monthyear.push(data[i].monthyear);
      logincount.push(parseInt(data[i].logins));
    }
    const options = {
      chart: {
        renderTo: 'container',
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 0,
          beta: 0,
          depth: 20,
        },
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: monthyear,
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'In Nos',
          align: 'center',
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        valueSuffix: ' No(s)',
      },
      plotOptions: {
        series: {
          pointPadding: 0.3,
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.f}',
          },
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Total login count',
          data: logincount,
          color: '#5B9BD5',
        },
        // {
        //   name: 'Total login time',
        //   data: logintime,
        //   color: '#82d55b',
        // },
      ],
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}
